<template>
	<div>
		<tap :taps="['股东介绍']" :isMore="false"></tap>
		<div class="content">
			<div class="shareholder">
				<div class="title">{{detail.title}}</div>
				<p v-html="detail.subject"></p>
			</div>
		</div>
	</div>
</template>

<script>
	 export default{
		 data(){
			return{
				detail:{}
			}
		 },
		 created() {
		 	this.$api.about.getSubDail(10,this).then(res=>{
		 		this.detail = res[0]
		 	})
		 },
	 }
</script>

<style lang="less" scoped>
	.content{
		color: #333;
		display: flex;
		margin-top: 20px;
		.shareholder{
			flex: 1;
			.title{
				font-size: 20px;
				font-weight: 600;
				margin: 20px 0;
				text-align: center;
			}
		}
		.image,.image1{
			width: 40%;
			img{
				width: 100%;
				padding: 20px;
				border: 1px solid rgba(235, 236, 239, 1);
				box-sizing: border-box;
				max-height: 70vh;
			}
		}
	}
	p{
		font-size: 16px;
		line-height: 28px;
		margin: 16px 0;
		text-indent: 2em;
	}
	@media (min-width:1300px){
		.content{
			.shareholder{
				margin-right: 20px;
			}
			.image1{
				display: block;
			}
			.image{
				display: none;
			}
		}
	}
	@media (max-width:1300px){
		.shareholder{
				margin-right: 0;
			}
		.content{
			flex-wrap: wrap;
			.image{
				display: block;
				width: 100%;
			}
			.image1{
				display: none;
			}
		}
	}
</style>